import React, {useState, useEffect} from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image"
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import Rozcestnik from "../../components/Rozcestnik";
import styled from "styled-components";
import Breadscrum from "../../components/Breadscrum";
import FAQ from "../../components/FrequentlyAskedQ";
import { Swiper, SwiperSlide } from 'swiper/react';
import OnlineParteImg from "../../assets/img/onlineParte.svg";
import ReviewBackground from "../../assets/img/parteReviewBack.svg";
import { useMediaQuery } from 'react-responsive';
import axios from "axios";
import Loading from "../../assets/img/loadingTransparent.svg";
import JumbotronV2 from "../../components/brandingV2/JumbotronV2";
import LinkButtonV2 from "../../components/brandingV2/LinkButtonV2";


const faqData = [
  {
    otazka: "Jak funguje online parte?",
    odpoved: "Po registraci na našich stránkách vás webová aplikace bude navádět postupnou tvorbou parte. Když je všechno hotovo a parte je zveřejněno, můžete jej pomocí sociálních sítí sdílet se svými blízkými a přáteli, kteří mohou vkládat komentáře a sdílet své vzpomínky, které na zesnulého mají. I po zveřejnění můžete v parte upravovat a provádět změny."
  },
  {
    otazka: "Platí se za zveřejnění?",
    odpoved: "Ne. Tvorba a zveřejnění parte online je zcela zdarma."
  },
  {
    otazka: "Co musí online parte obsahovat?",
    odpoved: "Parte by mělo obsahovat celé jméno zesnulého, fotku, datum narození, datum a místo úmrtí a krátký text. Další vzhled a obsah parte je jen a jen na vás. Můžete se rozloučit pomocí jedné věty či verše, ale můžete se o zesnulém i rozepsat a napsat několik odstavců o jeho povaze, životě nebo úspěších.. Stejně tak můžete vybrat jednu fotku, ale můžete jich sdílet i několik."
  },
  {
    otazka: "Kdo všechno může komentovat parte?",
    odpoved: "Příspěvek k parte může napsat kdokoli, pamatujte ale, že všechny komentáře by měly být uctivé a důstojné a to jak k zesnulému, tak k jeho pozůstalým."
  },
  {
    otazka: "Jak funguje vybírání peněz přes online parte?",
    odpoved: "Pokud chcete pomocí online parte získat peníze, které pomohou s organizací posledního rozloučení nebo vybrat příspěvek pro neziskovou organizaci zabývající se tématem, které bylo zesnulému blízké, vyberete nebo založíte při vytváření parte sbírku na Donio.cz. Sbírka může být aktivní, jak dlouho si budete přát. Po jejím ukončení budou vybrané peníze připsány na váš bankovní účet."
  },
  {
    otazka: "Jak si můžu založit vlastní sbírku na Donio?",
    odpoved: 'K založení sbírky stačí na Donio.cz vyplnit forlumář, ve kterém mimo jiné uvedete i důvody založení sbírky a jak plánujete peníze využít. Poté je třeba počkat na schválení sbírky, které většinou trvá několik málo dní. Podrobnější informace o zakládání vlastní sbírky si můžete  přečíst na tomto <a href="https://www.donio.cz/navrh">odkazu</a>.'
  },
];


const ParteInner = styled.div`

  .nowrapReview {
    font-family: inherit;
    white-space: nowrap;
  }

  .mainHeading {
    font-size: 50px !important;

    @media (max-width: 899px) {
      font-size: 38px !important;
    }
  }

  .jumboImage {
    @media (max-width: 1050px) {
      width: 300px !important;
      height: 210px !important;
    }

    @media (max-width: 899px) {
      width: 230px !important;
      height: 180px !important;
    }
  }

  .recent {
    background: #F7F7F7;
    padding: 30px 0;
    padding-top: 35px;

    .swiper-wrapper {
      margin-left: 30px !important;
    }

    .zadneParte {
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #999999;
      text-align: center;
      display: block;
      margin: 30px;
      margin-bottom: 0;
    }

    .loadingImage {
      margin: 0 auto;
      display: block;
      padding: 20px 0;
      height: 120px;
    }

    h2 {
      font-family: NewKansas;
      color: #243A58;
      font-size: 22px;
      text-align: center;
    }

    .swiper-container {
      width: 100%;
      margin: 0;
      /* padding: 30px 25px; */
      padding: 30px 0;
    }

    /* .swiper-wrapper {
      width: 100%;
    }
*/
    .swiper-slide-active {
      /* margin-left: 25px; */
      /* transition: margin 100ms; */
    } 

    .swiper-slide {
      /* transition: margin 100ms; */
      width: fit-content;

      a {
        text-decoration: none;
        text-align: left;
        /* width: fit-content; */
      }
    }

    a {
      font-family: Visuelt-Regular;
      font-size: 18px;
      color: #999999;
      text-align: center;
      margin: 0 auto;
      display: block;
    }
  }

  .features {
    margin: -20px auto;
    max-width: 600px;
    width: calc(100% - 50px);

    @media (min-width: 900px) {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      width: calc(100% - 100px);
      max-width: 1150px;
      margin: auto;
    }

    .feature {
      margin: 80px auto;
      width: calc(100% - 50px);
      max-width: 600px;

      @media (min-width: 700px) {
        width: calc(100%/3 - 30px);
        margin: 80px 0;
      }

      .featureImg {
        margin: 0 auto;
        display: block !important;
        margin-bottom: 35px;
      }

      h3 {
        font-family: Visuelt-Medium;
        font-size: 22px;
        color: #000000;
        text-align: center;
        margin-bottom: 15px;
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #808080;
        text-align: center;
        line-height: 25px;
      }
    }
  }

  .reviews {
    background: url(${ReviewBackground});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px 0;

    .reviewMobile {
      background: #FFFFFF;
      box-shadow: 0 5px 10px 0 rgba(0,0,0,0.08);
      border-radius: 5px;
      width: calc(100% - 20px - 50px);
      padding: 25px;
      margin: 0 auto;
      max-width: 600px;

      img {
        width: 105px;
      }
      
      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000000;
        line-height: 24px;
        margin: 15px auto;
      }

      .clovek {
        display: flex;
        align-items: center;
        margin-top: 25px;

        .featureImg {
          border-radius: 100%;
        }

        .clovekInner {
          margin-left: 15px;

          .jmeno {
            display: block;
            font-family: Visuelt-Medium;
            font-size: 16px;
            color: #000000;
          }

          .mesto {
            display: block;
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #7F7F7F;
          }
        }
      }
    }

  }

  .vyhledavaniMesta {
    background: #F7F7F7;
    padding-top: 35px;
    padding-bottom: 5px;
    margin-bottom: 60px;

    h2 {
      font-family: NewKansas;
      font-size: 22px;
      color: #243A58;
      text-align: center;
    }

    section {
      margin: 0 auto;
    }
  }

  .proPohrebniSluzby {
    margin: 0 auto;
    width: calc(100% - 50px);
    max-width: 600px;
    border-top: 1px solid #DFDFDF;
    padding-top: 60px;

    @media (min-width: 900px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1150px;
      width: calc(100% - 100px);
    }

    .left {
      @media (min-width: 900px) {
        max-width: 575px;
        width: calc(100% - 400px);
      }

      h2 {
        font-family: NewKansas;
        font-size: 25px;
        color: #243A58;

        @media (min-width: 900px) {
          font-size: 32px;
          margin-bottom: 40px;
        }
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #3D3D3D;
        line-height: 24px;
        margin-top: 30px;
      }
    }

    .right {
      .parterImg {
        display: block;
        margin-top: 30px;
      }
    }
  }

  .callToAction {
    background: #F7F7F7;
    padding: 35px 0;
    margin: 60px auto;

    @media (min-width: 900px) {
      margin: 100px auto;
    }

    h2 {
      font-family: Visuelt-Medium;
      font-size: 22px;
      color: #000000;
      text-align: center;
      width: calc(100% - 50px);
      margin: 0 auto;
      margin-bottom: 30px;
    }

    a {
      background: #3A557C;
      border-radius: 5px;
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #FFFFFF;
      text-align: center;
      padding: 20px 0;
      text-decoration: none;
      width: calc(100% - 50px);
      max-width: 310px;
      margin: 0 auto;
      display: block;

      &:hover {
        background: #2A4164;
      }
    }
  }

  .faq {
    margin-top: 60px;
    margin-bottom: 60px;

    @media (min-width: 900px) {
      margin-top: 90px;
      margin-bottom: 90px;
    }
  }
`;




function datesToAge(deathDateO, birthDateO) {
  var deathDate = Date.parse(deathDateO);
  var birthDate = Date.parse(birthDateO);
  return (Math.round((deathDate - birthDate)/1000/60/60/24/365));
}

function dateToYear(dateO) {
  var date = Date.parse(dateO);
  date = new Date(date);
  return (date.getFullYear());
}

const PartePreviewInner = styled.div`
  width: fit-content;

  .previewBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,0.15);
    border-radius: 10px;
    padding: 8px 20px;
    width: 238px;
    height: 80px;
  }

  .left {
    .obrazek {
      width: 60px;
      height: 60px;
      overflow: hidden;
      border-radius: 100%;
      position: relative;
      background: url(${props => props.imageUrl});
      background-position: center;
      background-size: cover;
    }
  }

  .right {
    margin-left: 13px;
    width: 165px;

    .name {
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #000000;
      display: block;
      margin-bottom: 5px;
    }

    .info {
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #808080;
      display: block;
    }
  }

  /* a {
    font-family: Visuelt-Medium;
    font-size: 14px;
    color: #000000;
    padding: 11px 14px;
    background: #ECECEC;
    border-radius: 5px;
    text-decoration: none;
    margin: 0;

    &:hover {
      background: #c3c3c3;
    }
  } */
`;

const PartePreview = ({ imageUrl, name, deathDate, birthDate, slug }) => {
  
  const age = datesToAge(deathDate, birthDate);
  const deathYear = dateToYear(deathDate);
  const birthYear = dateToYear(birthDate);

  
  return (
    <PartePreviewInner imageUrl={imageUrl}>
      <Link to={`/prt/${slug}/`}>
        <div className="previewBlock">
          <div className="left">
            <div className="obrazek" />
          </div>
          <div className="right">
            <span className="name">{name}</span>
            <span className="info">{birthYear} - {deathYear}, {age} let</span>
          </div>
        </div>
        {/* <Link to={`/prt/${slug}/`}>Zobrazit</Link> */}
      </Link>
    </PartePreviewInner>
  )
};







let structuredData = {
  "@context": "https://schema.org",
  "@type": "Product",
  "name":"Parte Online",
  "brand":"Goodbye.cz",
  "image":"https://goodbye.cz/rakev.jpg",
  "description":"Vytvořte si zdarma interaktivní smuteční oznámení (parte) online. To potom můžete jednoduše posílat ostatním pozůstalým a společně tak sdílet vzpomínky, fotky a příběhy, které jste s vaším blízkým zažili.",
  "aggregateRating": {
    "@type":"AggregateRating",
    "bestRating": "5",
    "ratingCount": 74,
    "ratingValue": 4.92,
    "worstRating": "1"}
};





const Parte = ({ data }) => {

  const isPlus900 = useMediaQuery({
    query: '(min-width: 900px)'
  });

  let backendUrl = "";

  if (process.env.NODE_ENV === 'development') {
    backendUrl = 'http://localhost:1337'
  }

  if (process.env.NODE_ENV === 'production') {
    backendUrl = 'https://goodbye-backend.herokuapp.com'
  }


  const [parteData, setParteData]  = useState();


  useEffect(() => {

    axios.get(`${backendUrl}/partes?_limit=-1`).then(res => {

      console.log(res);

      if (res.data.length === 0) {
        setParteData("nope");
      }

      else {
        setParteData(res.data.reverse());
      }

    });

  }, []);


  let pocitadlo = 0;

  return (
    <Layout
      subMenuLong
      forcePohrbyMenu
      customCtaText={"Vytvořit parte"}
      customCtaLink={"/vytvorit-parte/"}
      subMenuItems={[
        {
          name: "Pohřební služba",
          url: "/pohrebni-sluzba/",
        },
        {
          name: "Kde fungujeme?",
          url: "/pobocky/",
        },
        {
          name: "Parte",
          url: "/parte/",
        },
        {
          name: "Kremace bez obřadu",
          url: "/kremace-bez-obradu/",
        },
        {
          name: "Pohřeb s obřadem",
          url: "/pohreb-s-obradem/",
        },
        {
          name: "Netradiční pohřeb",
          url: "/netradicni-pohreb/",
        },
        {
          name: "Pohřeb miminka",
          url: "/pohreb-miminka/",
        },
      ]}
    >
      <SEO
        customTitle
        title="Vytvořit zdarma: Parte, smuteční oznámení (online) | Goodbye.cz"
        description="Vytvořte si zdarma interaktivní smuteční oznámení, parte. To můžete jednoduše sdílet mezi ostatní pozůstalé. Sdílet vzpomínky, fotky a příběhy."
        image={"/parteOg.png"}
      />

      <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(structuredData)}}/>

      <ParteInner>


        <JumbotronV2
          noSplatky
          jumboObrazek={data.jumboObrazek.childImageSharp.fixed}
          jumboObrazekMobile={data.jumboObrazekMobile.childImageSharp.fixed}
          h1={"Online parte"}
          hideSecondHeading
          p={<>Vytvořte si zdarma interaktivní <Link to="/poradna/pruvodce-tvorbou-smutecniho-oznameni/">smuteční oznámení (parte)</Link> online. To potom můžete jednoduše posílat ostatním pozůstalým a společně tak sdílet vzpomínky, fotky a příběhy, které jste s vaším blízkým zažili.</>}
          reviewDesc={<>Nejlépe hodnocená pohřební služba za 2022</>}
          breadscrum={
            <Breadscrum
              visible={true}
              stranky={[
                {name: "Domů", url: "/"},
                {name: "Pohřební služba", url: "/pohrebni-sluzba/"},
                {name: "Online parte", url: "/parte/"},
              ]}
            />
          }
          customCta={[
            <LinkButtonV2 to="/vytvorit-parte/" arrow text="Vytvořit zdarma" />,
          ]}
        />

        <div className="recent">
          <h2>Poslední vytvořené parte</h2>


          {parteData ? <>
            {(parteData === "nope") ?
              <span className="zadneParte">Žádné parte ještě není vytvořeno</span>
            :
              <Swiper slidesPerView={"auto"} spaceBetween={20}>
                {parteData.map(parte => {
                  if (parte.isPublished === true) {
                    pocitadlo++;

                    let fotka = parte.profilePicture.url;

                    if (typeof parte.profilePicture.formats !== "undefined") {
                      fotka = parte.profilePicture.formats.thumbnail.url;
                    }


                    return (
                      <SwiperSlide key={pocitadlo}>
                        <PartePreview
                          imageUrl={fotka}
                          name={parte.name}
                          deathDate={parte.deathDate}
                          birthDate={parte.birthDate}
                          slug={parte.slug}
                        />
                      </SwiperSlide>
                    );
                  }
                })}
              </Swiper>
            }
          </>:
            <img className="loadingImage" src={Loading} alt="Načítání..." />
          }



          {(parteData !== "nope") &&
            <Link to="/parte/posledni-vytvorene/">Zobrazit všechny</Link>
          }



        </div>
          
        <div className="features">
          <div className="feature">
            <Img className="featureImg" alt="Sdílejte mezi své blízké" fixed={data.parteSdilejte.childImageSharp.fixed} />
            <h3>Sdílejte mezi své blízké</h3>
            <p>Vytvořte online parte, které budete pomocí odkazu moci jednoduše sdílet s rodinou a známými.</p>
          </div>
          <div className="feature">
            <Img className="featureImg" alt="Vyberte potřebné peníze" fixed={data.parteDonio.childImageSharp.fixed} />
            <h3>Vyberte potřebné peníze</h3>
            <p>Pomozte vybrat peníze na dobročinný projekt nebo založte vlastní sbírku - například na finanční pomoc rodině.</p>
          </div>
          <div className="feature">
            <Img className="featureImg" alt="Vzpomínejte společně" fixed={data.parteBubliny.childImageSharp.fixed} />
            <h3>Vzpomínejte společně</h3>
            <p>Přidávejte fotky, pište příspěvky a společně tak vytvořte velkou koláž vzpomínek na vašeho blízkého.</p>
          </div>
        </div>

        {/* <div className="reviews">
          <div className="reviewMobile">
            <img alt="" src={Stars} />
            <p>“I can't say enough about Tulip. Cremations are confusing, particularly when you are consumed by stress and emotion. I contacted a few providers and felt rushed by others. Tulip you brought compassion and care at a stressful time. Your attention to detail didn't go unnoticed. Thank you”</p>
            <div className="clovek">
              <Img className="featureImg" alt="Sdílejte mezi své blízké" fixed={data.parteSamplePicReview.childImageSharp.fixed} />
              <div className="clovekInner">
                <span className="jmeno">Pan Novák</span>
                <span className="mesto">Praha</span>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="vyhledavaniMesta">
          <h2>Vyhledat podle města</h2>
          <div>
            <div>
              <MestaSliderParte data={data} />
            </div>
          </div>
        </div> */}

        <div className="proPohrebniSluzby">
          <div className="left">
            <h2>Pro pohřební služby</h2>
            <p>Jste pohřební služba a chcete svým klientům poskytovat možnost mít parte online? Neváhejte se nám ozvat - vše vám rádi vysvětlíme a k používání zaučíme. Naším cílem je poskytovat lidem jednoduché místo pro sdílení vzpomínek na jejich blízké, nezáleže na tom, u jaké pohřební služby sjednávají pohřeb.</p>
          </div>
          
          <div className="right">
            {isPlus900 ?
              <Img className="parterImg" alt="Sdílejte mezi své blízké" fixed={data.domekBig.childImageSharp.fixed} />
            :
              <Img className="parterImg" alt="Sdílejte mezi své blízké" fixed={data.domek.childImageSharp.fixed} />
            }
          </div>
        </div>

        <div className="callToAction">
          <h2>Online parte dokáže vytvořit úplně každý již do 5 minut</h2>
          <Link to="/vytvorit-parte/">Vytvořit zdarma</Link>
        </div>

        <FAQ className="faq" data={faqData} />
      </ParteInner>

      <Rozcestnik zaveti pohrby poradna />
    </Layout>
  );
}


export const query = graphql`
  query {

    jumboObrazek: file(relativePath: { eq: "parteJumbo.png" }) {
      childImageSharp {
        fixed(width: 450) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    jumboObrazekMobile: file(relativePath: { eq: "parteJumbo.png" }) {
      childImageSharp {
        fixed(width: 230) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }


    small: file(relativePath: { eq: "jumboImage.png" }) {
      childImageSharp {
        fixed(width: 240) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    big: file(relativePath: { eq: "jumboImage.png" }) {
      childImageSharp {
        fixed(width: 360) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    parteSamplePic: file(relativePath: { eq: "parteSamplePic.png" }) {
      childImageSharp {
        fixed(width: 60) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    parteSamplePicReview: file(relativePath: { eq: "parteSamplePic.png" }) {
      childImageSharp {
        fixed(width: 45) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    parteSdilejte: file(relativePath: { eq: "parteSdilejte.png" }) {
      childImageSharp {
        fixed(width: 144) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    parteDonio: file(relativePath: { eq: "parteDonio.png" }) {
      childImageSharp {
        fixed(width: 152) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    parteBubliny: file(relativePath: { eq: "parteBubliny.png" }) {
      childImageSharp {
        fixed(width: 145) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    domek: file(relativePath: { eq: "jakToFunguje2.png" }) {
      childImageSharp {
        fixed(width: 180) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    domekBig: file(relativePath: { eq: "jakToFunguje2.png" }) {
      childImageSharp {
        fixed(width: 319) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    brno: file(relativePath: { eq: "brno.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    ceskeBudejovice: file(relativePath: { eq: "ceske-budejovice.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    hradecKralove: file(relativePath: { eq: "hradec-kralove.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    liberec: file(relativePath: { eq: "liberec.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    olomouc: file(relativePath: { eq: "olomouc.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    ostrava: file(relativePath: { eq: "ostrava.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    pardubice: file(relativePath: { eq: "pardubice.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    plzen: file(relativePath: { eq: "plzen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    praha: file(relativePath: { eq: "praha.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    ustiNadLabem: file(relativePath: { eq: "usti-nad-labem.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Parte;
